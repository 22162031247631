@font-face {
  font-family: Overpass;
  src: url("Overpass-Regular.236aedd2.woff2") format("woff2");
}

:root {
  --color-pure-red: #b20230;
  --color-dark-purple: #2a2231;
  --color-light-blue: #ddf0ff;
  --color-medium-blue: #3e6e99;
  --color-header-below: 42 34 49;
  --color-orange: #f06543;
  --color-orange-secondary: #f4937b;
  --color-silver-blue: #cee8ff;
  --color-dark-grey-rgb: 221 240 255;
  --color-pink: #91436a;
  --color-green: #387d7a;
  --color-fast-animation: #9c9640;
  --color-grey: #2a223180;
  --color-white: #fff;
  --color-dropdown: #cfe5fa;
  --color-black-rgb: 0 0 0;
  --color-white-rgb: 255 255 255;
  --color-orange-rgb: 240 101 67;
  --color-grey-rgb: 42 34 49;
  --color-pale-blue-rgb: 81 124 162;
  --color-blue-navlink: #bbcfe2;
  --color-blue-focus-rgb: 62 110 153;
  --color-pink-rgb: 178 2 48;
  --color-nav-link: #abd6fd;
  --color-line-rgb: 102 102 102;
  font-family: Overpass, sans-serif;
}

body {
  margin: 0;
}

#root {
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--color-light-blue);
  flex-direction: column;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.G-ITfa_backdrop, ::backdrop {
  opacity: .7;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: #000;
  position: absolute;
  overflow-y: hidden;
}

.G-ITfa_container {
  width: 100vw;
  z-index: 30;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  display: flex;
  position: fixed;
  top: 25%;
  left: 0;
}

.G-ITfa_popup {
  box-sizing: border-box;
  width: 90%;
  max-width: 300px;
  height: fit-content;
  background: url("popup_bubbles.d0fa99cb.svg") no-repeat bottom left, var(--color-silver-blue);
  box-shadow: 1px 6px 9px 4px rgb(var(--color-black-rgb) / 20%);
  color: var(--color-dark-purple);
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px 20px;
  display: flex;
}

.G-ITfa_heading {
  letter-spacing: .13px;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 24px;
  padding-top: 90px;
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
}

.G-ITfa_infoHeading {
  background: url("iconBIG_info.54295d85.svg") top no-repeat;
}

.G-ITfa_attentionHeading {
  background: url("iconBIG_attention.928c8611.svg") top no-repeat;
}

.G-ITfa_spinnerHeading {
  background: url("puff.e5673ff9.svg") top no-repeat;
  padding-top: 80px;
}

.G-ITfa_signatureHeading {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.G-ITfa_signatureHeading:before {
  content: "";
  height: 18px;
  width: 10px;
  background: url("icon_DID.ddfe09a8.svg") center no-repeat;
  display: inline-block;
}

.G-ITfa_imprintHeading {
  background: url("KILT-Horizontal-black.246bc0e9.png") top / auto 100px no-repeat;
  padding-top: 130px;
  font-size: 14px;
}

.G-ITfa_text {
  letter-spacing: .13px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}

.G-ITfa_bottomText {
  margin: 0;
}

.G-ITfa_buttonWrapper {
  height: 22px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
}

.G-ITfa_dismissButton {
  letter-spacing: .1px;
  height: 22px;
  width: 130px;
  background-color: var(--color-medium-blue);
  color: var(--color-white);
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-top: 3px;
  font-size: 12px;
  display: inline-flex;
}

.G-ITfa_okayButton {
  width: 100px;
}

.G-ITfa_cancelButton {
  width: 100px;
}

.G-ITfa_imprint {
  bottom: 113px;
  top: initial;
}

.G-ITfa_imprintPopup {
  max-width: 484px;
  color: var(--color-dark-purple);
  border-radius: 8px;
  gap: 2px;
}

.G-ITfa_imprintText {
  letter-spacing: .1px;
  text-align: center;
  margin: 0;
  line-height: 22px;
}

.G-ITfa_imprintText a {
  color: var(--color-medium-blue);
}

.G-ITfa_imprintBottomText {
  margin-top: 15px;
}

.G-ITfa_errorText {
  text-align: center;
}

.G-ITfa_link {
  color: inherit;
}

.MqZHCq_container {
  width: 100vw;
  flex-direction: column;
  display: flex;
}

.MqZHCq_primary {
  height: 76px;
  background: url("PrimaryHeaderLeftBubbles.d89ff26f.svg") no-repeat left 18px / auto 100%, url("PrimaryHeaderRightBubbles.6f9eefba.svg") no-repeat right / auto 110%, var(--color-dark-purple);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.MqZHCq_logoContainer {
  max-width: 766px;
  width: 90%;
}

.MqZHCq_logoButton {
  width: 168.5px;
  height: 52px;
  cursor: pointer;
  background: url("logo_DIDsign.6aaf1c1d.svg") no-repeat;
  border: none;
  display: block;
}

.MqZHCq_secondary {
  width: 100vw;
  height: 30px;
  background: rgb(var(--color-header-below) / 90%) no-repeat;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.MqZHCq_text {
  max-width: 766px;
  width: 90%;
  color: var(--color-light-blue);
  letter-spacing: .1px;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
}

@media (max-width: 600px) {
  .MqZHCq_text {
    visibility: hidden;
  }
}

.BOBUQq_footer {
  width: 100%;
  min-height: 34px;
  background: var(--color-dark-purple);
  border-top: 1px solid var(--color-silver-blue);
  place-content: center;
  display: flex;
}

.BOBUQq_content {
  max-width: 766px;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  display: flex;
}

@media (max-width: 700px) {
  .BOBUQq_content {
    width: 100%;
    flex-direction: column;
  }
}

.BOBUQq_navMenu {
  align-items: center;
  gap: 10px;
  display: flex;
}

@media (max-width: 700px) {
  .BOBUQq_navMenu {
    width: 100%;
    border-bottom: 1px solid rgb(var(--color-line-rgb) / 75%);
    place-content: center;
    padding: 7px 0;
    display: flex;
  }
}

.BOBUQq_navLink {
  color: var(--color-nav-link);
  line-height: 20px;
  text-decoration: none;
}

.BOBUQq_navLinkActive {
  opacity: .5;
}

.BOBUQq_copyright {
  color: var(--color-white);
  background: url("built-on-KILT.7796ce9a.svg") 100% no-repeat;
  margin: 0;
  padding: 7px 90px 0 0;
  font-size: 10px;
  line-height: 20px;
}

@media (max-width: 700px) {
  .BOBUQq_copyright {
    margin: 0 0 7px;
  }
}

.mil_aG_bottomContainer {
  width: 100vw;
  min-height: 95px;
  background: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mil_aG_bottomSection {
  box-sizing: border-box;
  max-width: 766px;
  background-color: rgb(var(--color-dark-grey-rgb) / 50%);
  width: 90%;
  min-height: 95px;
  border: 1px solid;
  border-color: rgb(var(--color-pale-blue-rgb) / 25%);
  box-shadow: 0 2px 4px 0 rgb(var(--color-pale-blue-rgb) / 10%);
  border-radius: 0 0 15px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.mil_aG_infoLink {
  width: 90%;
  max-width: 766px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.mil_aG_infoItem {
  letter-spacing: .2px;
  padding: 0 .2rem;
  font-size: 12px;
  line-height: 12px;
}

.mil_aG_infoItem a {
  color: var(--color-medium-blue);
}

.mil_aG_infoItem:first-child:after {
  content: "|";
  padding-left: .5rem;
}

.mil_aG_verificationLoader {
  height: 40px;
  width: 38px;
  background: url("puff.e5673ff9.svg") center / 100% no-repeat;
  display: inline-block;
  position: absolute;
  top: 24px;
  right: 30px;
}

.mil_aG_verificationText {
  color: var(--color-grey);
  letter-spacing: .11px;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  position: absolute;
  top: 24px;
  left: 40px;
}

div.mil_aG_verificationText {
  background-color: rgb(var(--color-dark-grey-rgb) / 50%);
  height: 1px;
  width: 15px;
}

.mil_aG_startOverButton {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: url("button_start_over_NEW.da6d6131.svg") center / 100% no-repeat;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

._5l06ia_main {
  width: 100vw;
  background: url("BottomLeftBubble.80be3fcc.svg") no-repeat bottom left / auto 40%, url("BottomRightBubble.000dacaf.svg") no-repeat bottom right / auto 40%, var(--color-light-blue);
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 100px;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

._5l06ia_middleSection {
  width: 100%;
  background: url("CenterLeftBubble.f9c8d01a.svg") no-repeat bottom left / auto 20%, url("CenterRightBubble.72119800.svg") no-repeat bottom right / auto 20%, var(--color-silver-blue);
  flex-direction: column;
  align-items: center;
  display: flex;
}

._5l06ia_container {
  max-width: 766px;
  width: 90%;
  height: 220px;
  margin-top: 12px;
  display: flex;
  position: relative;
}

._5l06ia_dropContainer {
  max-width: 100%;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

._5l06ia_signText {
  pointer-events: none;
  color: #fff;
  text-align: center;
  letter-spacing: .11px;
  font-size: 16px;
  line-height: 17px;
  position: absolute;
  top: 32px;
}

._5l06ia_dragDropText {
  pointer-events: none;
  color: #fff;
  text-align: center;
  letter-spacing: .11px;
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  top: 56px;
}

._5l06ia_browseFilesText {
  pointer-events: none;
  color: #fff;
  text-align: center;
  letter-spacing: .11px;
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  bottom: 48px;
}

._5l06ia_importIcon {
  margin: auto;
  position: absolute;
}

._5l06ia_bottomContainer {
}

._5l06ia_bottomSection {
}

._5l06ia_verificationLoader {
}

._5l06ia_verificationText {
}

._5l06ia_startOverButton {
}

.RjStBW_container {
  width: 766px;
  height: 220px;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -1px 6px #00000026, inset 0 3px 8px #00000026;
}

.RjStBW_containerVerifier {
  background-color: var(--color-green);
}

.RjStBW_containerFast {
  background-color: var(--color-fast-animation);
}

.RjStBW_containerSlow {
  background-color: var(--color-pink);
}

.RjStBW_circle {
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border: 50px solid #fff0;
  border-radius: 50%;
  margin-top: -100px;
  margin-left: -100px;
  animation: RjStBW_animation 5s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

.RjStBW_gradient {
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #0003 0, #8989891a 50%, #ffffff1a);
  position: absolute;
}

.RjStBW_circle2 {
  animation-delay: 1s;
}

.RjStBW_circle3 {
  animation-delay: 2s;
}

.RjStBW_circle4 {
  animation-delay: 3s;
}

.RjStBW_circle5 {
  animation-delay: 4s;
}

@keyframes RjStBW_animation {
  from {
    width: 1500px;
    height: 1500px;
    border-width: 200px;
    border-color: #fff0;
    margin-top: -750px;
    margin-left: -750px;
  }

  to {
    width: 10px;
    height: 10px;
    border-width: 5px;
    border-color: #ffffff26;
    margin-top: -5px;
    margin-left: -5px;
  }
}

.mmWWyW_navContainer {
  width: 100%;
  background: rgb(var(--color-header-below) / 90%) no-repeat;
  justify-content: center;
  display: flex;
}

.mmWWyW_navbar {
  max-width: 766px;
  width: 90%;
  align-items: flex-end;
  gap: 18px;
  display: flex;
}

.mmWWyW_navLink {
  width: 374px;
  color: var(--color-dark-purple);
  text-transform: uppercase;
  letter-spacing: .11px;
  height: 28px;
  background-color: var(--color-blue-navlink);
  cursor: pointer;
  border: none;
  border-radius: 3px 3px 0 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  line-height: 17px;
  text-decoration: none;
  display: flex;
  box-shadow: inset 0 -1px 6px #00000026;
}

.mmWWyW_navLinkActive {
  letter-spacing: .16px;
  height: 35px;
  background-color: var(--color-silver-blue);
  font-size: 18px;
  line-height: 20px;
}

.mmWWyW_signUnderline {
  height: 2px;
  width: 130px;
  background-color: var(--color-pink);
  margin-bottom: 1px;
}

.mmWWyW_verifyUnderline {
  height: 2px;
  width: 130px;
  background-color: var(--color-green);
  margin-bottom: 1px;
}

.mmWWyW_activeUnderline {
  height: 4px;
}

.MZdsPG_container {
  box-sizing: border-box;
  max-width: 766px;
  min-height: 211px;
  max-height: 900px;
  width: 90%;
  background-color: rgb(var(--color-dark-grey-rgb) / 50%);
  border-style: solid;
  border-color: rgb(var(--color-pale-blue-rgb) / 25%);
  border-width: 1px 1px 0;
  align-content: start;
  column-gap: 20px;
  padding-right: 20px;
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}

.MZdsPG_heading {
  color: var(--color-dark-purple);
  letter-spacing: .11px;
  justify-content: flex-end;
  margin: 0;
  padding-top: 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 17px;
  display: flex;
}

.MZdsPG_list {
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 10px 0 6px;
  list-style: none;
  display: flex;
}

.MZdsPG_file {
  box-sizing: border-box;
  letter-spacing: .1px;
  border-bottom: dotted 2px var(--color-medium-blue);
  min-height: 40px;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 16px 5px 0;
  font-size: 14px;
  line-height: 16px;
  display: flex;
}

.MZdsPG_fileName {
  color: var(--color-dark-purple);
  min-height: 30px;
  background-position: 0 0;
  background-repeat: no-repeat;
  align-items: center;
  margin: 0;
  padding-left: 30px;
  display: flex;
}

.MZdsPG_didsignFile {
  color: var(--color-pure-red);
  background-image: url("doc_signature_NEW.a9e73121.svg");
}

.MZdsPG_imageFile {
  background-image: url("doc_image.d2706898.svg");
}

.MZdsPG_docFile {
  background-image: url("doc_generic.91cc64f0.svg");
}

.MZdsPG_iconButton {
  height: 18px;
  width: 18px;
  cursor: pointer;
  border: none;
  padding: 0;
}

.MZdsPG_deleteButton {
  background: url("icon_delete.66927620.svg") center / 110% no-repeat;
}

.w__Xsa_container {
  grid-template: "w__Xsa_heading w__Xsa_list"
  / 15% auto;
}

.w__Xsa_heading {
  color: var(--color-grey);
  grid-area: w__Xsa_heading;
}

.w__Xsa_list {
  flex-direction: column;
  grid-area: w__Xsa_list;
  gap: 45px;
  padding-top: 40px;
  display: flex;
}

.w__Xsa_emptyLines {
  opacity: .8;
  border: .2px dotted #4d6b85;
}

.gUs5mG_container {
  grid-template: "gUs5mG_heading gUs5mG_list"
  / 15% auto;
}

.gUs5mG_heading {
  grid-area: gUs5mG_heading;
}

.gUs5mG_list {
  grid-area: gUs5mG_list;
}

.gUs5mG_file {
}

.gUs5mG_docFile {
}

.gUs5mG_imageFile {
}

.gUs5mG_didsignFile {
}

.gUs5mG_deleteButton {
}

.gUs5mG_infoButton {
  background: url("icon_info.53bef094.svg") 100% / 110% no-repeat;
}

.gUs5mG_didsignInfo {
  color: var(--color-pure-red);
  align-items: center;
  gap: 10px;
  margin: 0;
  font-size: 12px;
  line-height: 13px;
  display: flex;
}

.EgmvgG_container {
  box-sizing: border-box;
  min-height: 45px;
  border-bottom: dotted 2px var(--color-medium-blue);
  padding: 5px 2px 5px 25px;
}

.EgmvgG_timestamp {
  min-height: 34px;
  background: rgb(var(--color-orange-rgb) / 20%);
  border-radius: 2px;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding-right: 6px;
  display: flex;
}

@media (max-width: 850px) {
  .EgmvgG_timestamp {
    flex-direction: column;
    padding: 5px;
  }
}

.EgmvgG_heading {
  min-height: 34px;
  background: url("timestamp.f1e74489.svg") 5px 50% no-repeat;
  align-items: center;
  margin: 0;
  padding-left: 36px;
  font-size: 12px;
  font-weight: normal;
  display: inline-flex;
}

.EgmvgG_section {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}

@media (max-width: 750px) {
  .EgmvgG_section {
    flex-direction: column;
    gap: 5px;
  }
}

.EgmvgG_fee {
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin: 0;
  display: flex;
}

.EgmvgG_button {
  width: 100px;
  height: 22px;
  background: var(--color-orange);
  color: var(--color-white);
  text-transform: uppercase;
  cursor: pointer;
  border-style: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  display: inline-flex;
}

.EgmvgG_button:disabled {
  background: var(--color-orange-secondary);
  color: rgb(var(--color-white-rgb) / 50%);
  pointer-events: none;
}

.EgmvgG_select {
  width: 355px;
  color: var(--color-dark-purple);
  font-size: 14px;
  position: relative;
}

@media (max-width: 600px) {
  .EgmvgG_select {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .EgmvgG_select {
    width: 200px;
  }
}

.EgmvgG_openButton {
  border-top: 1px solid var(--color-dark-purple);
  border-right: 1px solid var(--color-dark-purple);
  border-left: 1px solid var(--color-dark-purple);
  border-bottom: 1px solid var(--color-dark-purple);
  height: 22px;
  width: 100%;
  text-align: start;
  background: url("chevron_down_dark.1a7a36ff.svg") no-repeat top 8px right 9px, var(--color-dropdown);
  cursor: pointer;
  color: rgb(var(--color-grey-rgb) / 75%);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 2px 2px 0 0;
  margin: 0;
  padding: 2px 30px 2px 6px;
  font-size: 14px;
  overflow: hidden;
}

.EgmvgG_selectedAccount {
  color: inherit;
}

.EgmvgG_closeButton {
  background: url("chevron_up_dark.6571bf52.svg") no-repeat top 8px right 9px, var(--color-dropdown);
  border-bottom: none;
}

.EgmvgG_options {
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: var(--color-dark-purple);
  background: var(--color-dropdown);
  border-radius: 0 0 2px 2px;
  padding: 0 30px 0 6px;
  list-style: none;
  position: absolute;
  left: 0;
  right: 0;
}

.EgmvgG_option {
  box-sizing: border-box;
  height: 33px;
  border-top: 2px dotted var(--color-medium-blue);
  cursor: pointer;
  align-items: center;
  padding: 2px 0;
  display: flex;
}

.EgmvgG_option:hover, .EgmvgG_option:focus {
  background: content-box rgb(var(--color-orange-rgb) / 20%);
}

.EgmvgG_option p {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  overflow: hidden;
}

.AeNFYa_spinner {
  height: 18px;
  width: 18px;
  background: url("puff.e5673ff9.svg") center / 100% no-repeat;
  display: inline-block;
}

.reataq_grid {
  box-sizing: border-box;
  border-bottom: dotted 2px var(--color-medium-blue);
  grid-template: "reataq_arrow reataq_list"
  / 1.5em auto;
  column-gap: 5px;
  padding-top: 5px;
  display: grid;
}

.reataq_list {
  grid-area: reataq_list;
  padding-top: 0;
}

.reataq_listItem {
  border-bottom: dotted 2px var(--color-medium-blue);
}

.reataq_listItem:last-child {
  height: 35px;
  border: none;
}

.reataq_arrowIcon {
  background: url("arrow_nested.d27db1ab.svg") no-repeat;
  grid-area: reataq_arrow;
  margin-top: 5px;
  margin-left: 5px;
}

.reataq_credentialContainer {
  border: none;
  gap: 5px;
}

.reataq_editing {
  background-color: rgb(var(--color-blue-focus-rgb) / 20%);
}

.reataq_error {
  background-color: rgb(var(--color-pink-rgb) / 20%);
}

.reataq_credentialLabel {
  background: url("credential.bad69ab4.svg") no-repeat;
}

.reataq_inputContainer {
  max-width: 330px;
  width: 95%;
  color: var(--color-dark-purple);
  height: 23px;
  background-color: #fff;
  border: none;
  justify-content: flex-start;
  margin: 0;
  display: flex;
  position: relative;
}

.reataq_input {
  width: 100%;
}

.reataq_inputError {
  letter-spacing: .09px;
  color: #fff;
  background: url("error_icon.d3303333.svg") no-repeat 1% 40% / 4%, var(--color-dark-purple);
  margin: 0;
  padding-left: 20px;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
}

.reataq_name {
  max-width: 330px;
  width: 53%;
  color: var(--color-dark-purple);
  height: 23px;
  border: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.reataq_editingInfo {
  color: var(--color-pure-red);
  width: 80%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 0;
  font-size: 12px;
  line-height: 13px;
  display: flex;
}

.reataq_editContainer {
  max-width: 150px;
  width: 40%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  display: flex;
}

@media (max-width: 500px) {
  .reataq_credentialLabel, .reataq_editingInfo, .reataq_inputError, .reataq_name {
    font-size: 10px;
  }
}

.reataq_editButton {
  background: url("edit.acca22dd.svg") 100% / 110% no-repeat;
}

.reataq_deleteButton {
}

.jZm-yq_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
}

.jZm-yq_buttonContainer {
  gap: 8px;
  display: flex;
}

.jZm-yq_infoButton {
  cursor: pointer;
  width: 18px;
  background: url("icon_info.53bef094.svg") 100% / 100% no-repeat;
  border: none;
}

.jZm-yq_signButton {
  letter-spacing: .1px;
  color: #fff;
  max-width: 160px;
  cursor: pointer;
  width: 60vw;
  height: 30px;
  background-color: var(--color-medium-blue);
  text-transform: uppercase;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  display: flex;
}

.jZm-yq_signButton:disabled {
  opacity: .6;
}

._5ZmXYq_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

._5ZmXYq_zipButtonWrapper {
  letter-spacing: .1px;
  max-width: 300px;
  width: 70%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
}

._5ZmXYq_zipText {
  width: 90px;
  text-align: right;
  color: var(--color-dark-purple);
}

._5ZmXYq_downloadSignText {
  width: 70px;
  text-align: right;
  color: var(--color-dark-purple);
  font-size: 14px;
  line-height: 16px;
}

._5ZmXYq_zipButton {
  width: 160px;
  height: 30px;
  color: #fff;
  background-color: var(--color-medium-blue);
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

._5ZmXYq_progressbarWrapper {
  width: 220px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 2px;
  margin-bottom: 6px;
  margin-left: 2px;
  display: flex;
}

._5ZmXYq_progressbar {
  width: 160px;
  height: 6px;
  border: solid 1px var(--color-medium-blue);
  border-radius: 10px;
}

._5ZmXYq_progress {
  background-color: var(--color-medium-blue);
  height: 100%;
  border-radius: 10px;
}

._5ZmXYq_progressInfo {
  width: 160px;
  text-align: center;
  height: 10px;
  letter-spacing: .1px;
  color: var(--color-dark-purple);
  font-size: 12px;
  line-height: 16px;
}

._5ZmXYq_downloadSignButtonWrapper {
  max-width: 260px;
  width: 60%;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  display: flex;
}

._5ZmXYq_downloadSignButton {
  width: 130px;
  height: 22px;
  background-color: var(--color-medium-blue);
  color: #fff;
  letter-spacing: .09px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  line-height: 14px;
}

.tUwlOW_container {
  grid-template: "tUwlOW_heading tUwlOW_list"
  / 15% auto;
}

.tUwlOW_heading {
  grid-area: tUwlOW_heading;
}

.tUwlOW_list {
  grid-area: tUwlOW_list;
}

.tUwlOW_file {
}

.tUwlOW_docFile {
}

.tUwlOW_imageFile {
}

.tUwlOW_didsignFile {
}

.tUwlOW_deleteButton {
}

.tUwlOW_fileOk {
  background: url("icon_ok.59ba513b.svg") right 32px center no-repeat content-box content-box;
}

.tUwlOW_fileInvalid {
  background: url("icon_attention.56177c32.svg") right 32px center no-repeat content-box content-box;
}

.tUwlOW_zipContainer {
  grid-template: "tUwlOW_zip tUwlOW_zip"
                 "tUwlOW_heading tUwlOW_list"
                 / 15% auto;
}

.tUwlOW_zipFile {
  width: 90%;
  grid-area: tUwlOW_zip;
  justify-self: end;
  padding: 10px 16px 5px 0;
}

.tUwlOW_zipFilename {
  background-image: url("doc_zip_NEW.7c5d3264.svg");
}

.tUwlOW_unzippedFileOk {
  background: url("icon_ok.59ba513b.svg") 100% no-repeat content-box content-box;
}

.tUwlOW_unzippedFileInvalid {
  background: url("icon_attention.56177c32.svg") 100% no-repeat content-box content-box;
}

._0f33-q_container {
  width: 100%;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 100px;
  display: flex;
}

._0f33-q_textWrapper {
  width: 90%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

._0f33-q_verificationText {
  color: green;
  letter-spacing: .11px;
  width: 80px;
  background: url("icon_ok.59ba513b.svg") right 30% no-repeat;
  justify-content: center;
  padding-right: 35px;
  font-size: 16px;
  line-height: 25px;
  display: flex;
}

._0f33-q_verificationIcon {
  width: 70%;
  justify-content: flex-start;
  display: flex;
}

._0f33-q_text {
  color: var(--color-dark-purple);
  letter-spacing: .1px;
  word-break: break-all;
  width: 550px;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  display: flex;
}

._0f33-q_credentialContainer {
  max-width: 550px;
  width: 95%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

._0f33-q_credentialsWrapper {
  box-sizing: border-box;
  color: var(--color-dark-purple);
  word-break: normal;
  width: 100%;
  border-bottom: 2px dotted #4d6b85;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2px;
  display: flex;
}

._0f33-q_anchor {
  color: var(--color-medium-blue);
  background: url("icon_link.8b59f219.svg") right 40% no-repeat;
  margin-right: 50%;
  padding-right: 20px;
}

._0f33-q_subscan {
  margin-right: auto;
  padding-left: 10px;
}

._0f33-q_errorText {
  color: var(--color-orange);
  word-break: normal;
}

._0f33-q_endpointsWrapper {
  color: var(--color-dark-purple);
  letter-spacing: .11px;
  word-break: normal;
  max-width: 550px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
}

._0f33-q_title {
  color: var(--color-dark-purple);
  letter-spacing: .11px;
  width: 80px;
  text-align: left;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 25px;
  display: flex;
}

._0f33-q_errorTitle {
  color: var(--color-orange);
}

._0QyTMG_container {
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  border-bottom: 1px dotted var(--color-dark-purple);
  flex-direction: column;
  gap: 2px;
  display: flex;
}

._0QyTMG_endpointTypeContainer {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

._0QyTMG_endpoint {
  color: var(--color-dark-purple);
  letter-spacing: .1px;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  overflow: hidden;
}

._0QyTMG_endpointUrl {
  letter-spacing: .1px;
  max-width: 400px;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 22px;
}

._0QyTMG_fetchButton {
  text-transform: uppercase;
  width: 130px;
  height: 22px;
  color: #fff;
  background: url("chevron_down_white.ce6e3910.svg") no-repeat 90% / auto, var(--color-medium-blue);
  cursor: pointer;
  border: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 12px;
  line-height: 13px;
  display: flex;
  position: relative;
}

._0QyTMG_closeButton {
  background: url("chevron_up_white.6a5c7a82.svg") no-repeat 90% / auto, var(--color-medium-blue);
}

._0QyTMG_loader {
  background: url("spinning-circles.e991925a.svg") no-repeat 10% / 10%, url("chevron_up_white.6a5c7a82.svg") no-repeat 90% / auto, var(--color-medium-blue);
}

._0QyTMG_spinner {
  display: absolute;
  height: 15px;
  width: 12px;
  background: url("spinning-circles.e991925a.svg") 0 / 100% no-repeat;
  top: 5px;
  left: 5px;
}

._0QyTMG_credentials {
  margin: 0;
  padding: 0;
  list-style: none;
}

._0QyTMG_credential {
  width: 100%;
  border-bottom: 1px dotted rgba(var(--color-pale-blue-rgb) / 25%);
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 18px;
  padding-bottom: 18px;
  display: flex;
}

._0QyTMG_credential:last-child {
  border: none;
  padding: 0;
}

._0QyTMG_error {
}

._0QyTMG_collapse {
  color: var(--color-medium-blue);
  cursor: pointer;
  background: url("chevron_up_blue.fe99a904.svg") 100% no-repeat;
  border: none;
  align-self: flex-end;
  margin-bottom: 8px;
  padding-right: 12px;
  font-family: Overpass, sans-serif;
  font-size: 12px;
  display: inline-flex;
}

.euWd7a_heading {
  margin: 0;
}

.euWd7a_property {
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  display: flex;
}

.euWd7a_name {
  max-width: 100px;
  width: 20%;
  letter-spacing: .1px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}

.euWd7a_value {
  letter-spacing: .1px;
  width: 80%;
  max-width: 400px;
  font-size: 14px;
  line-height: 22px;
  display: block;
}

.euWd7a_anchor {
  color: var(--color-medium-blue);
  background: url("icon_link.8b59f219.svg") right 40% no-repeat;
  padding-right: 20px;
}

.euWd7a_valid {
  background: url("icon_ok.59ba513b.svg") no-repeat;
}

.euWd7a_invalid {
  background: url("icon_attention.56177c32.svg") no-repeat;
}

.KPb48G_container {
  margin-bottom: 30px;
}

.KPb48G_wrapper {
}

.KPb48G_verificationText {
  letter-spacing: .11px;
  width: 80px;
  color: var(--color-orange);
  background: url("icon_attention.56177c32.svg") right 30% no-repeat;
  justify-content: center;
  padding-right: 35px;
  font-size: 16px;
  line-height: 25px;
  display: flex;
}

.KPb48G_title {
}

.KPb48G_text {
}

.ie8BPa_container {
  width: 100%;
  height: calc(100vh - 140px);
  background: url("BottomLeftBubble.80be3fcc.svg") no-repeat bottom left / auto 40%, url("BottomRightBubble.000dacaf.svg") no-repeat bottom right / auto 40%, var(--color-silver-blue);
  justify-content: center;
  display: flex;
}

.ie8BPa_contents {
  max-width: 740px;
  width: 90%;
  color: var(--color-dark-purple);
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  display: flex;
}

.ie8BPa_heading {
  letter-spacing: .1px;
  font-size: 40px;
}

.ie8BPa_anchor {
  color: var(--color-dark-purple);
  font-size: 20px;
}

.ie8BPa_text {
  font-size: 20px;
}

.xf6ESq_container {
  width: 100%;
  background: var(--color-silver-blue);
  flex: 1;
  place-content: center;
  display: flex;
}

.xf6ESq_content {
  max-width: 766px;
  width: 90%;
  letter-spacing: .1px;
  color: var(--color-dark-purple);
  padding: 36px 0 100px;
  font-size: 14px;
  line-height: 22px;
}

.xf6ESq_heading {
  letter-spacing: .13px;
  margin: 0 0 27px;
  font-size: 18px;
  font-weight: normal;
}

.xf6ESq_sectionHeading {
  margin: 16px 0;
  font-size: 14px;
}

.BAVe-q_container {
  width: 100%;
  background: var(--color-silver-blue);
  flex: 1;
  place-content: center;
  display: flex;
}

.BAVe-q_content {
  max-width: 766px;
  width: 90%;
  letter-spacing: .1px;
  color: var(--color-dark-purple);
  padding: 36px 0 100px;
  font-size: 14px;
  line-height: 22px;
}

.BAVe-q_heading {
  letter-spacing: .13px;
  margin: 0 0 27px;
  font-size: 18px;
  font-weight: normal;
}

.BAVe-q_sectionHeading {
  margin: 16px 0;
  font-size: 14px;
}

.BAVe-q_listHeading {
}

.BAVe-q_anchor {
  text-decoration: none;
}

.tvHLFG_container {
  width: 100%;
  background: var(--color-silver-blue);
  flex: 1;
  place-content: center;
  display: flex;
}

.tvHLFG_content {
  max-width: 766px;
  width: 90%;
  letter-spacing: .1px;
  color: var(--color-dark-purple);
  padding: 36px 0 100px;
  font-size: 14px;
  line-height: 22px;
}

.tvHLFG_heading {
  margin: 0 0 60px;
  font-size: 24px;
}

.tvHLFG_section {
  margin: 16px 0 0;
}

.tvHLFG_line {
  margin: 0;
}

.tvHLFG_anchor {
  text-decoration: none;
}

/*# sourceMappingURL=index.b5c75be7.css.map */
