.container {
  display: grid;
  column-gap: 20px;
  box-sizing: border-box;
  align-content: start;
  max-width: 766px;
  min-height: 211px;
  max-height: 900px;
  width: 90%;
  padding-right: 20px;
  overflow: hidden auto;
  background-color: rgb(var(--color-dark-grey-rgb) / 50%);
  border-style: solid;
  border-color: rgb(var(--color-pale-blue-rgb) / 25%);
  border-width: 1px 1px 0;
}

.heading {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding-top: 20px;
  color: var(--color-dark-purple);
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.11px;
  font-weight: normal;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 10px 0 6px;
}

.file {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  border-bottom: dotted 2px var(--color-medium-blue);
  padding: 0 16px 5px 0;
  margin: 0;
  min-height: 40px;
}

.fileName {
  color: var(--color-dark-purple);
  margin: 0;
  padding-left: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top left;
}

.didsignFile {
  composes: fileName;
  background-image: url('doc_signature_NEW.svg');
  color: var(--color-pure-red);
}

.imageFile {
  composes: fileName;
  background-image: url('doc_image.svg');
}

.docFile {
  composes: fileName;
  background-image: url('doc_generic.svg');
}

.iconButton {
  height: 18px;
  width: 18px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.deleteButton {
  composes: iconButton;
  background: url('icon_delete.svg') no-repeat center/110%;
}
