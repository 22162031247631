@font-face {
  font-family: Overpass;
  src: url('fonts/Overpass-Regular.woff2') format('woff2');
}

:root {
  --color-pure-red: #b20230;
  --color-dark-purple: #2a2231;
  --color-light-blue: #ddf0ff;
  --color-medium-blue: #3e6e99;
  --color-header-below: 42 34 49;
  --color-orange: #f06543;
  --color-orange-secondary: #f4937b;
  --color-silver-blue: #cee8ff;
  --color-dark-grey-rgb: 221 240 255;
  --color-pink: #91436a;
  --color-green: #387d7a;
  --color-fast-animation: #9c9640;
  --color-grey: #2a223180;
  --color-white: #fff;
  --color-dropdown: #cfe5fa;
  --color-black-rgb: 0 0 0;
  --color-white-rgb: 255 255 255;
  --color-orange-rgb: 240 101 67;
  --color-grey-rgb: 42 34 49;
  --color-pale-blue-rgb: 81 124 162;
  --color-blue-navlink: #bbcfe2;
  --color-blue-focus-rgb: 62 110 153;
  --color-pink-rgb: 178 2 48;
  --color-nav-link: #abd6fd;
  --color-line-rgb: 102 102 102;

  font-family: Overpass, sans-serif;
}

body {
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden auto;
  background-color: var(--color-light-blue);
}
